import React, { useState } from 'react'

import axios from 'axios';
import { convertDateFormat, convertDateFormatInputDate, formatOnlyTime } from '../../../../../../util/formatsForDate';
import Input from '../../../../../ui/Input';
import TimeInput from '../../../../../ui/TimeInput';

const NewSignature_ActiviyDay = ({ showModal, getData, handleColse, row = "", GET_URL,fetchData }) => {
    const POST_URL=`admin/qualification/signature_windows/${fetchData?.id}`
    const [formData, setFormData] = useState(row ? {

        // date: "2023-12-31",
         date: convertDateFormatInputDate(fetchData?.date),
        timeStart: formatOnlyTime(row?.timeStart),
        timeEnd: formatOnlyTime(row?.timeEnd),
    } : {
        date: convertDateFormatInputDate(fetchData.date),
        timeStart: "00:00:00",
        timeEnd: "00:00:00"
    })
    const [errors, setErrors] = useState({});
    // console.log(formData)

    const validateField = (name, value) => {
        let error = "";

        switch (name) {
            case "timeStart":
                if (!value.trim()) {
                    error = "הינו שדה חובה."
                }
                if (value.trim() && !/^\d+$/.test(value)) {
                    error = "מספר טלפון חייב להיות מספרים בלבד.";
                }
                break;
            case "timeEnd":
                if (!value.trim()) {
                    error = " הינו שדה חובה.";
                }
                break;



            default:
                break;
        }

        return error;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        const fieldError = validateField(name, value);
        setErrors({ ...errors, [name]: fieldError });
    };

    const handleChange1 = (name, value) => {
        console.log(name, value)
        setFormData({
            ...formData,
            [name]: value,
        });
        const fieldError = validateField(name, value);
        setErrors({ ...errors, [name]: fieldError });
    };

    const validate = () => {
        const newErrors = {};

        // if (!formData?.date?.trim()) {
        //     newErrors.date = "הינו שדה חובה.";
        // }
        if (!formData?.timeStart?.trim()) {
            newErrors.timeStart = "הינו שדה חובה."
        }
        if (!formData?.timeEnd?.trim()) {
            newErrors.timeEnd = "הינו שדה חובה.";
        }




        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };




    const handleSubmit = (e) => {
        // e.preventDefault();
        if (validate()) {
            // console.log('טופס נשלח בהצלחה:', (`${formData.date} ${formData.timeStart}`));
            // console.log('טופס נשלח בהצלחה:', (`${convertDateFormat(formData?.date)}${formData.timeStart}`));
            addNewActivityday()
            // כאן אפשר לשלוח את הנתונים לשרת או לבצע פעולות נוספות
        }
    }








    const addNewActivityday = async () => {
        const datecon = convertDateFormat(formData?.date)
        const response = await axios.post(POST_URL, JSON.stringify({
            timeStart: `${datecon} ${formData.timeStart}`,
            timeEnd: `${datecon} ${formData.timeEnd}`,

        }), {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log(response)
        if (response?.status == 200) {
            // setnamecalss("")
            getData()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }
    const handleEditActivityDay = async (id) => {
        const datecon = convertDateFormat(formData.date)
        await axios.put(`${POST_URL}/${id}`, JSON.stringify({
            date: `${datecon} 00:00:00`,
            timeStart: `${datecon} ${formData.timeStart}`,
            timeEnd: `${datecon} ${formData.timeEnd}`,
        }), {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.status == 200) {
                getData()
                handleColse()
                console.log("Edit")
            }
        })
    }
    return (
        <div className='absolute top-0 h-screen overflow-y-auto left-0 flex flex-col items-center pt-5 w-full drop-shadow-2xl shadow-2xl bg-[#FDFFFC]/50 '
        // onClick={() => {
        //     // handleColse()
        // }}
        >
            <div className='bg-white w-fit rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
                <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>
                {/* <div className=' w-full divide-y-2  divide-slate-500'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div> */}
                <form className='w-full p-4 flex flex-col  text-center text-sm ' dir='rtl'>
                    {/* <div className='grid grid-cols-10 ' >
                        <div className='col-span-3' >תאריך</div>
                        <input type='date' className='col-span-2 text-right' id={"date"} name={"date"} onChange={handleChange} value={formData?.date} />
                        < div className="text-red-500 text-center">{errors?.date && <p>{errors?.date}</p>}</div>
                    </div> */}
                    {/* <Input
                        label='תאריך'
                        req={true}
                        className='grid col-span-3  border'
                        type="date"
                        id="date"
                        name="date"
                        error={errors?.date}
                        value={formData?.date}
                        onChange={handleChange}
                    /> */}


                    <TimeInput labal="שעת התחלה" id={"timeStart"} name={"timeStart"} handleChange1={handleChange1} value={formData?.timeStart} />
                    <TimeInput labal="שעת סיום" id={"timeEnd"} name={"timeEnd"} handleChange1={handleChange1} value={formData?.timeEnd} />


                </form>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        {
                            // console.log(formData)
                            row ? handleEditActivityDay(row.id) : handleSubmit()
                        }


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>
            {/* </Modall> */}
            </div>)

}

export default NewSignature_ActiviyDay