import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import SignatureModal from './SignatureModal';
import BtnSendDis from '../../assets/senddis.png'
import BtnSendAct from '../../assets/sendactive.png'
import axios from 'axios';
import Cookies from "universal-cookie"
// import Modal from './Modal';

const Signature = ({ getLocation, location, getDataSignatures, steps, onNext, setErrMess, onError }) => {
  const [showModal, setShowModal] = useState(false);
  const [dataSign, setdataSign] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [loading, setLoading] = useState(false)
  const [signature1, setSignature1] = useState(null)
  const [file, setFile] = useState(null)
  const cookies = new Cookies();

  function generateRandomSixDigitNumber() {

    const signId = cookies.get('sId');
    if (signId) {
      return signId;
    }
    else {
      var newSignId = Math.floor(100000 + Math.random() * 900000);
      const expires = new Date();
      expires.setDate(expires.getDate() + 1); // הגדרת זמן תפוגה ל-1 יום
      cookies.set('sId', newSignId, { path: '/', expires: expires });
      return newSignId;
    }
  }

  const handelSubmit = async () => {
    await getLocation()
    const GET_URL = `dashboard/member/member_sign/${steps?.qul_id}/${steps?.signatureWindows_id}`
    const formData1 = new FormData();
    formData1.append('longitude', location.longitude);
    formData1.append('latitude', location.latitude);
    formData1.append('uniqId', `${generateRandomSixDigitNumber()}`);
    formData1.append('sign_member', file);
    const response = await axios.post(GET_URL, formData1

      ,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    if (response?.status == 200) {
      // setnamecalss("")
      console.log(response)
      setErrMess(response?.data || "החתימה הושלמה בהצלחה")
      await getDataSignatures()
      await onNext()

    }
    else {
      console.log("error add class")
      setErrMess(response?.response?.data?.message)
      await onError()
    }
  }
  const sign = useRef(null)

  function clearSign() {
    sign.current.clear()
    setdataSign(false)
    // console.log(sign)
  }
  function onClose() {
    setShowModal(false)
    // console.log(sign)
  }

  return (
    steps?.signatureWindows_id &&
    <div className='w-full flex flex-col items-center '>
      {/* 
        <div>
              <h2>מיקום נוכחי:</h2>
              {location.latitude && location.longitude ? (
                <p>קואורדינטות: {`Latitude: ${location.latitude}, Longitude: ${location.longitude}`}</p>
              ) : (
                <p>מחפש מיקום...</p>
              )}
        </div> */}
      <label>

      </label>
      {/* <input type="image" className='w-full h-[150px] bg-LightBlue border-[0.5px] border-dashed border-midnight rounded-md text-center' placeholder='חתימה כאן' onClick={()=>{setShowModal(true)}} value={imageURL?<img className='w-full h-9' src={imageURL}/>:null}/>  */}
      <div className='w-[300px] h-[250px] bg-[#eff4fe] border-[0.5px] border-dashed border-[#121063] rounded-md flex justify-center items-center' onClick={() => {
        setShowModal(true)
        // setdataSign(false)
      }} >
        {imageURL ? <img className='max-h-[200px] ' src={imageURL} /> : <p className='w-full text-center font-bold '>לחתימה יש ללוחץ כאן</p>}
      </div>
      {showModal && steps?.signatureWindows_id &&
        <div className showModal={showModal} setShowModal={setShowModal} >
          {/* <div className='w-full text-center text-sm font-heebo  '>
                    <p className='font-bold'>חתימה</p>
                    <p>יש לחתום בתוך המשבת המסומנת</p>
                </div>
                <SignatureCanvas canvasProps={{ className: 'w-[full] h-[200px] bg-LightBlue border my-2 border-MidBlue rounded-[4px]' }} ref={sign} onEnd={setSignatureOnChange}></SignatureCanvas>
                <div className='w-full flex justify-between font-heebo font-bold  text-lg'>
                    {dataSign?
                        <button type='button' className='px-8 w-fit rounded-md text-white bg-PraimeryBlue' onClick={save}>שמור</button> :
                        <button type='button' className='px-8 w-fit rounded-md bg-Grey100 text-Grey300 disabled'>שמור</button>
                    }
                    {dataSign?
                    <button type='button' className='p-4 w-fit rounded-md bg-LightBlue ' onClick={clearSign}>נקנה חתימה</button>
                    :<button type='button' className='p-4 w-fit rounded-md bg-Grey100 text-Grey300 disabled' >נקנה חתימה</button>
                    }
                </div> */}
          <SignatureModal onClose={onClose} onSave={setImageURL} setdataSign={setdataSign} setFile={setFile} />
        </div>}
      <button type="button" disabled={loading||!dataSign} className={`${dataSign&& !loading ? "send-button" : "send-button-dis "} mt-3`} onClick={async () => {
        setLoading(true)
        await handelSubmit()
        setLoading(false)
      } }
      >
        {loading ? (
          // אייקון טעינה - ספירלה מסתובבת
          <span className="flex items-center">
            <svg className="animate-spin h-10 w-10 text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
          </span>
        ) : (

          <>
            <img src={dataSign ? BtnSendAct : BtnSendDis} alt="" />
            <span className={`${dataSign ? "send-text" : "send-text-dis"}`}>שליחה</span>
          </>)}
      </button>



    </div>

  )
}

export default Signature


