
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ReDataTable from '../../../../ui/ReDataTable';
import ModalEdit from '../../../../ModalEdit';
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import { MdAssignment } from "react-icons/md"
import { MdAssignmentReturned } from "react-icons/md"
import MoreOptionsCard from '../../../../ui/MoreOptionsCard';
import NewActivityday from './NewActivityday';
import { convertToIsraelTime, formatOnlyTime } from '../../../../../util/formatsForDate';
import { HeaderTable } from '../../../../HeaderTable';
import Signature_windows_ActiviyDay from './ActivityIdMoreOptions/Signature_windows_ActiviyDay';

const Activityday = ({ row }) => {
  const GET_URL = `admin/qualification/activity_day/${row?.id}`
  const [modalIsOpen, setModalIsOpen] = useState("");
  const [id, setId] = useState("");
  const [fetchData, setFetchData] = useState([])
  const [loading, setloading] = useState(true)
  const [totalRows, setTotalRows] = useState(0);

  const getData = async (page, perPage) => {
    const response = await axios.get(GET_URL, {
      params: {
        "page": page
        , "size": perPage,
      }
    })
    if (response.status == "200") {
      const response1 = (response?.data)
      const response2 = (response?.data?.totalElements);
      return [response1, response2];
    }
    const response1 = []
    const response2 = []
    return [response1, response2];
  };

  const getDataTable = async (page, perPage) => {



    const [result1, result2] = await getData(page, perPage)
    setFetchData(result1);
    setTotalRows(result2);
    setloading(false)
    console.log(fetchData)
  };
  const handleColse = () => {
    setModalIsOpen("")
  }

  const handleDetele = async () => {

    await axios.delete(`${GET_URL}/${id.id}`).then(response => {
      if (response.status == 200) {
        getDataTable(0, 10)
        handleColse()

        console.log("ok")
      }
      else {
        console.log("error")
      }
    })
  }
  const columns = [

    {
      name: "פעולות",
      grow: 2,
      cell: row => <div className='w-full'>

        <button title="אפשרויות נוספות" className='  ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
          setId(row)
          console.log(row)
          setModalIsOpen("MoreOptions")
        }}><MdAssignment /></button>
        <button title="מחיקה" className='  ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
          setId(row)
          setModalIsOpen("Delete")
        }}><IoTrashOutline /></button>

        <button className='  p-1  ml-1 opacity-25 hover:opacity-100' onClick={() => {
          // setnamecalss(row?.description)
          // setAction("edit")
          setId(row)
          console.log(row)
          setModalIsOpen("Edit")
        }
        }><BsPencil /></button>
      </div>,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },

    }
    ,

    {
      name: "שעת סיום",
      grow: 2,
      selector: (row) => row?.timeEnd ? formatOnlyTime(row?.timeEnd) : "",
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      sortable: true,
    },
    {
      name: "שעת התחלה",
      grow: 2,
      selector: (row) => row?.timeStart ? formatOnlyTime(row?.timeStart) : "",
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      sortable: true,
    },
    {
      name: "תאריך",
      grow: 2,
      selector: (row) => row?.date ? convertToIsraelTime(row?.date) : "",
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      sortable: true,
    }




  ];

  return (

    <div style={{ direction: "ltr" }} >
      <HeaderTable active={"ימי פעילות"} setModalIsOpen={setModalIsOpen} />
      <ReDataTable columns={columns} getDataTable={getDataTable} fetchData={fetchData} loading={loading} totalRows={totalRows} />
      {modalIsOpen == "New" && <NewActivityday showModal={modalIsOpen == "New"} GET_URL={GET_URL} handleColse={handleColse} getData={getDataTable} />}
      {modalIsOpen == "Edit" && <NewActivityday showModal={modalIsOpen == "Edit"} GET_URL={GET_URL} handleColse={handleColse} getData={getDataTable} row={id} />}
      {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetele}></ModalEdit>}
      {modalIsOpen == "MoreOptions" && <Signature_windows_ActiviyDay handleColse={handleColse} TrainingDays={row?.id} row={id?.id} qualificationName={row?.qualificationName}></Signature_windows_ActiviyDay>}
    </div>
  )
}

export default Activityday