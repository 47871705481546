import React, { useEffect, useState } from 'react'
import Stepper from '../copmponents/Stepper'
import Signature from '../copmponents/Signature'
import axios from 'axios'
import {formatSignWindow } from '../../util/formatsForDate'

const Signatures = () => {
    const GET_URL="dashboard/member/windows_open_for_attendance"
    const [stepsData, setStepsData] = useState({
        qul_id: null,
        signatureWindows_id:null,
        signatureWindowsDto:null,
        indexSignatures:null,
        numberSign:0})
    const [dataCoures, setDataCoures] = useState({
       
        numberCluster:"",
        nameCluster:"",
        numberQ:"",
        nameQ:"",
        description:"",
        place:"",
        nameInsturcation:"",
        signatureDates:{}

    })
    const [location , setLocation] = useState({
        latitude: 0, longitude: 0 
    })

    const getDataSignatures = async () => {

        await axios.get(GET_URL).then(response => {
            setDataCoures({ 
                
              
                numberCluster:response?.data?.qualificationDayDto?.cluster?.number,
                nameCluster:response?.data?.qualificationDayDto?.cluster?.name,
                numberQ:response?.data?.qualificationDayDto?.number,
                nameQ:response?.data?.qualificationDayDto?.name,
                description:response?.data?.qualificationDayDto?.description,
                place:response?.data?.qualificationDayDto?.place,
                nameInsturcation:response?.data?.qualificationDayDto?.instructor?.name,
                signatureDates:response?.data?.signatureWindowsDto
            }
        )
        setStepsData({
            qul_id: response?.data?.qul_id,
            signatureWindows_id: response?.data?.signatureWindows_id,
            signatureWindowsDto:response?.data?.signatureWindowsDto,
            indexSignatures:response?.data?.indexSignatures,
            numberSign:response?.data?.numberSign
        })    
     })
        // setloading(false)
    }

    const getLocation = () => 
    {
        if(navigator.geolocation)
        {
            navigator.geolocation.getCurrentPosition(
                (position => setLocation({ latitude: position.coords.latitude , longitude: position.coords.longitude }) 
            ) , () => {} ,  { enableHighAccuracy: true }) //err
        }
    }
    useEffect(()=>{
        getDataSignatures()
        getLocation()
    },[])
 
    useEffect(() =>
    {
        console.log("long: " + location.longitude)
        console.log("lati: " + location.latitude)
    } , [location])


    return (
        <div className='flex flex-col '>
           <h2 className='font-[#Actor] font-normal text-2xl text-[#333333] text-right'>דף חתימות נוכחות</h2>
           {stepsData.qul_id==null&&<p className='font-[#Poppins] font-semibold text-sm text-[#000000] text-right'>אין צורך בנוכחות כרגע </p>}
           {stepsData.qul_id&&<> 
            <p className='font-[#Poppins] font-semibold text-sm text-[#000000] text-right'>{dataCoures.nameQ}</p>
            <p className='font-[#Actor] font-normal text-[12px] text-[#000000] text-right'>{dataCoures.description}</p>
           
            <div className=" font-[#Actor] font-normal text-[12px] text-[#000000] text-right">
                {/* <p  className=''><b>מתי המפגש:</b> יום רביעי 12:00-14:00</p> */}
                <p><b>שם אשכול:</b> {dataCoures.nameCluster}</p>
                <p><b>מספר אשכול:</b> {dataCoures.numberCluster}</p>
                <p><b>שם של המדיך:</b> {dataCoures.nameInsturcation}</p>
                <p><b>מיקום:</b> {dataCoures.place}</p>
                {stepsData.numberSign&&<p><b>מספר חתימה:</b> {stepsData?.numberSign}</p>}
                {stepsData.numberSign&&<p><b>תאריך חתימה:</b> {formatSignWindow(dataCoures?.signatureDates)}</p>}
            </div>
        

{/* 
            <p className="  mt-2 text-xs text-[#333]">
                רשימת המתנה לפוליסת האחריות המקצועית של לשכת ממוני הבטיחות.<br />
                ביום עיון נלמד על אילו תחומים חלה פוליסת הבטיחות בעבודה:<br />
                • חידוש חוקים, תקנות ונהלים רלוונטיים<br />
                • ניהול סיכונים ודרכי מניעה<br />
                • דגשים חשובים בניהול מערכות בטיחות<br />
                • ועוד...
            </p> */}

           
            <Stepper getLocation ={getLocation} location ={location} steps={stepsData} currentStep={stepsData?.numberSign?stepsData.numberSign:(stepsData?.indexSignatures).length} getDataSignatures={getDataSignatures} />
            </>}
        </div>
    )
}
            
export default Signatures