import React from 'react'

const MoreOptionsCard = ({handleColse,children}) => {
  return (
    
    <div className=' fixed top-0 bottom-0 right-0 left-0 flex flex-col items-center py-4 w-full min-h-screen drop-shadow-2xl shadow-2xl bg-slate-600/80 overflow-y-auto'>
    <div className='bg-white w-[90%] rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
        <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
            <button className='p-1 px-2 m-3 border bg-blue-700 hover:bg-blue-500' onClick={() => {
                handleColse()
            }} >x</button>
        </div>
        {children}
        <div>
        <button className='border bg-blue-700 hover:bg-blue-500 p-1 text-lg px-3 my-3' onClick={() => {
                    // reset()
                    // setShowModal(false)
                    handleColse()
                }}>סגור</button>
            </div>
            </div>
        </div>
        
  )
}

export default MoreOptionsCard